import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { telephoneStrip } from '../utils/utils'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SeamlessIframe from "../components/SeamlessIframe"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const ContactPage = ({ pageContext, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

   // pull out get started
   const form = data.forms.nodes[0].allSeamlessForms
    .find(f => f.title.toLowerCase() === "contact")

  return (
    <Layout
      footerType={post.footerType}
      customNavButton={post.customNavButton}
    >
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />


      <div className="section contact-page">
        <div className="container has-text-centered">
          <h1>{post.heading}</h1>
          <MarkdownViewer markdown={post.blurb} />

          <h2 className="contact-phone">
            <a href={`tel:+1${telephoneStrip(data.phone.nodes[0].phoneNumber)}`}>
              {data.phone.nodes[0].phoneNumber}
            </a>
          </h2>

          <div id="form">
            <SeamlessIframe
              src={form.iframeSrc}
            />
          </div>
          
        </div>
      </div>

    </Layout>
  )
}

ContactPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const contactPageQuery = graphql`
  query contactQuery ($title: String!) {
    allUniquePagesJson(filter: {title: {eq: $title}}) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        formSrc
        footerType
        customNavButton {
          title
          href
          type
          hasButton
        }
      }
    }
    phone: allDataJson(filter: {optionsItem: {eq: "office"}}) {
      nodes {
        phoneNumber
      }
    }
    forms: allDataJson(filter: {optionsItem: {eq: "forms"}}) {
      nodes {
        allSeamlessForms {
          iframeSrc
          title
        }
      }
    }
  }
`

export default ContactPage